export const bestNameAwards = [
  {
    id: 'bn_0',
    name: 'Alison\'s Harem'
  },
  {
    id: 'bn_1',
    name: 'the anvil that broke the camel\'s back',
  },
  {
    id: 'bn_2',
    name: 'cats and their war crimes',
  },
  {
    id: 'bn_3',
    name: 'War war war war war war war',
  },
  {
    id: 'bn_4',
    name: 'Skintight Jorts',
  },
  {
    id: 'bn_5',
    name: 'Fuck everything',
  },
  {
    id: 'bn_6',
    name: 'concussion protocol',
  },
  {
    id: 'bn_7',
    name: 'Deus Ex Marinara',
  },
  {
    id: 'bn_8',
    name: 'But what IS metalcore?',
  },
  {
    id: 'bn_9',
    name: 'Best thing since sliced bees',
  },
  {
    id: 'bn_10',
    name: 'Dumbledore is a Head master',
  },
  {
    id: 'bn_11',
    name: 'the nuremberg smiles',
  },
  {
    id: 'bn_12',
    name: 'Bee soup on aisle 7',
  },
  {
    id: 'bn_13',
    name: 'Yasuo/Yone Fanfic Emporium',
  },
  {
    id: 'bn_14',
    name: 'the nip slip that ended an era',
  },
  {
    id: 'bn_15',
    name: 'Canker sores are worse than the Cold War',
  },
  {
    id: 'bn_16',
    name: 'Gandalf the Kind of Grey but Kind of Green sometimes in the right light',
  },
  {
    id: 'bn_17',
    name: 'My mind is telling me no but my body is telling me yes',
  },
  {
    id: 'bn_18',
    name: 'Unko Kunko Funko Pop',
  },
  {
    id: 'bn_19',
    name: 'bananas are nature’s peel-able penises',
  },
  {
    id: 'bn_20',
    name: 'Jabberhwhackin\' It',
  },
  {
    id: 'bn_21',
    name: 'being of unimaginable eldritch horror',
  },
  {
    id: 'bn_22',
    name: 'A napple a day keeps the doctor away',
  },
  {
    id: 'bn_23',
    name: 'Insomniatic Cryptids',
  },
  {
    id: 'bn_24',
    name: 'A corpse accelerating forward at frightening speeds',
  },
  {
    id: 'bn_25',
    name: 'BDSM - Brogurt, Decriminalization, Sounding, Meat/Math',
  },
  {
    id: 'bn_26',
    name: 'Breaking the Sounding Barrier',
  },
  {
    id: 'bn_27',
    name: 'mischievous eels',
  },
  {
    id: 'bn_28',
    name: 'Less eyes makes a cat more dog',
  },
];

export const bestAnswerAwards = [
  {
    id: 'ba_0',
    question: 'What was the first electronic instrument called?',
    answer: 'Kazoo', 
    name: 'swampbuttthing',
  },
  {
    id: 'ba_1',
    question: 'What is the term for a country characterized by the great majority of its people sharing the same culture?',
    answer: 'Racist',
    name: 'the anvil that broke the camel\'s back',
  },
  {
    id: 'ba_2',
    question: 'Auditobois: The Arthur theme song',
    answer: 'The Bob Marley Non-Porn Edition Animated Series',
    name: 'Stop the Steal',
  },
  {
    id: 'ba_3',
    question: 'Rapa Nui is famous for having almost 1000 statues called what?',
    answer: 'Garfield Creator Jim Davis (all capitalized because its a proper noun in its entirety)',
    name: 'The Hubris of Man Incarnate',
  },
  {
    id: 'ba_4',
    question: 'Two people invented calculus at the same time. Who?',
    answer: 'Newton and Bohrmann',
    name: 'An Armed Chili\'s Waitress',
  },
  {
    id: 'ba_5',
    question: 'What company did Michael J. Dupey found?',
    answer: 'Pornhub',
    name: 'askybois.cum',
  },
  {
    id: 'ba_6',
    question: 'In 2001, who became the most successful virtual band?',
    answer: 'Miss Pigly and the Undying Cucumbers',
    name: 'Green is a creative color',
  },
  {
    id: 'ba_7',
    question: 'What is the name of the ring of adamant?',
    answer: 'Stroopwafel Ring, the one ring to get them all sticky, the one ring to sweet them, the one ring to glue them all, and in the darkness syrup them',
    name: 'The Morally Reprehensible Couple',
  },
  {
    id: 'ba_8',
    question: 'Clark Gregg plays what S.H.I.E.L.D. agent?',
    answer: 'Xi Jinping',
    name: 'Dennis',
  },
  {
    id: 'ba_9',
    question: 'Lookybois: Identify the movie poster (Goodfellas).',
    answer: 'The Girl With a Garfield Tattoo',
    name: 'Fig Newtonian Physics',
  },
  {
    id: 'ba_10',
    question: 'Several best answers were awarded this day.',
    answer: 'I couldn\'t decide on one, so three teams won:',
    name: 'At least America got rid of slaves before wizards, PS2 Hagrid, and Fantastic Balls and Where to Torture Them',
  },
  {
    id: 'ba_11',
    question: 'Which saint and Doctor of the Catholic Church is known for the Summa Theologiae?',
    answer: 'Bernard, saint of wearing barrels on his neck for some reason',
    name: 'the nuremberg smiles',
  },
  {
    id: 'ba_12',
    question: 'What religious class fell between the nobility and the peasantry as far as their social standing?',
    answer: 'Priesty bois',
    name: 'Bee Soup on Aisle 7',
  },
  {
    id: 'ba_13',
    question: 'What is a Shurima Shuffle',
    answer: 'When one nearly drops their Shwarma and has to "slide to the right" to dodge the falling tzatziki',
    name: 'LoL was ruined when they took away hybrid Kayle',
  },
  {
    id: 'ba_14',
    question: 'In most of the English-speaking world, ‘venison’ refers to elk or deer meat. What animal’s meat does it refer to in South Africa?',
    answer: 'People from Venice',
    name: 'The digiorno man',
  },
  {
    id: 'ba_15',
    question: 'What company, named after its flagship game, has become a successful creator of party games?',
    answer: 'Pornhub',
    name: 'Logan',
  },
  {
    id: 'ba_16',
    question: 'What pass leads to Shelob\'s lair?',
    answer: 'the one with a big spider in it',
    name: 'Gimli, the son of Gimli, the son of Gimli...',
  },
  {
    id: 'ba_17',
    question: 'Before Wikipedia became popular, what product did Microsoft offer as a digital encyclopedia?',
    answer: 'Clippy',
    name: 'My mind is telling me no but my body is telling me yes',
  },
  {
    id: 'ba_18',
    question: 'What source of power does Loki use to transport the Chitauri?',
    answer: 'Dick',
    name: 'the team teeming with total tubularity',
  },
  {
    id: 'ba_19',
    question: 'Who is John Ratzenberger and why is he special in Pixar movies?',
    answer: 'He\'s always in the pipes, listening, murmuring.',
    name: 'Ben',
  },
  {
    id: 'ba_20',
    question: 'What ape, along with the chimpanzee, is the closest relative to humans?',
    answer: 'Trevor is closest to orangutans',
    name: 'soggy forgotten leftovers',
  },
  {
    id: 'ba_21',
    question: 'What is the smallest citrus fruit?',
    answer: 'Kumquat, as in "toss me a kummy"',
    name: 'Clammy Salmon Cannon',
  },
  {
    id: 'ba_22',
    question: 'What two movies feature Russell Brand as Aldous Snow?',
    answer: 'high school musical II (Czech version), and Band of Brothers On Ice!',
    name: 'chilliam bakedspeare',
  },
  {
    id: 'ba_23',
    question: 'What two movies feature Russell Brand as Aldous Snow?',
    answer: 'high school musical II (Czech version), and Band of Brothers On Ice!',
    name: 'chilliam bakedspeare',
  },
  {
    id: 'ba_24',
    question: 'Who are the four crown princes of hell?',
    answer: 'Asmodaeus, Mephisto, Justin Bieber, Tom Hanks',
    name: 'Grumpkin\'s Smelly Dumpkin',
  },
  {
    id: 'ba_25',
    question: 'What tool is Dremel known for?',
    answer: 'spinning bore kind of thing. like for grinding out one\'s teeth',
    name: 'Spiders? Yeah probably...',
  },
  {
    id: 'ba_26',
    question: 'Frederick the Great wqs king of what country that doesn\'t exist anymore?',
    answer: 'Finland, because Finland doesn\'t exist.',
    name: 'a sudden moment of deep frustration',
  },
  {
    id: 'ba_27',
    question: 'How do bees communicate?',
    answer: 'bzzzzfeed',
    name: 'Strutting our Stuffing',
  },
  {
    id: 'ba_28',
    question: 'What\'s another name for a penny whistle?',
    answer: 'A clean butthole',
    name: 'I drink my hot coffee through a straw',
  },
];
