const scores = [
  { name: 'Bottomless Gamer Girl Bathwater Gargling, New from Taco Belle', rank: 1, score: 81 },
  { name: 'Gilbert Gottfried\'s ASMR sex noises', rank: 2, score: 72 },
  { name: 'Where the Hell Have You Been, Banana Loca?', rank: 3, score: 53 },
  { name: 'CHICKEN PARMMM!!', rank: 3, score: 46 },
  { name: 'sally lightfoot\'s pinchy-pincers', rank: 5, score: 42 },
  { name: 'a single pair of buttocks', rank: 6, score: 38 },
  { name: 'Nathan', rank: 7, score: 25 },
];

export default scores;
