export const scienceCategories = [
  'the periodic table of elements',
  'particle physics',
  'geometry',
  'optics',
  'geology',
  'neuroscience',
  'palaeontology',
  'zoology',
  'electricity & magnetism',
  'black holes',
  'cryptology',
  'oceanography',
  'botany',
  'logic',
  'linguistics',
  'inorganic chemistry',
  'limbs',
  'trigonometry',
  'anthropology',
  'thermodynamics',
  'pedagogy',
  'ecology',
  'geomorphology',
  'cosmology',
  'algebra',
  'economics',
  'cognitive science',
  'biochemistry',
  'data science',
  'sociology',
  'morphosyntax',
  'quantum mechanics',
  'philosophy',
  'volcanology',
  'political science',
  'nebulae',
  'archaeology',
  'calculus',
  'cellular biology',
  'criminology',
  'motion',
  'law',
  'anatomy',
  'lasers',
  'circuitry',
];

export const entertainmentCategories = [
  'harry potter',
  'tarantino films',
  'shrek',
  'nintendo',
  'the matrix',
  'avatar: the last airbender',
  'middle earth',
  'name the actor',
  'board games',
  'classic rock',
  'pirates of the caribbean',
  'the incredibles',
  'a song of ice and fire',
  'mulan',
  'sketch comedy',
  'spider-man',
  'zombies',
  'bethesda games',
  'musical theatre',
  'dragons',
  'mmorpgs',
  'directors',
  'card games',
  'star wars: the original trilogy',
  'valve games',
  'jazz',
  'coen brothers films',
  'punk rock',
  'jurassic park',
  'tom hanks movies',
  'metal',
  'dreamworks films',
  'sonic the hedgehog',
  'electronic music',
  'indie games',
  'inception',
  'sitcoms',
  'star trek',
  '2000s music',
  'the avengers',
  'strategy games',
  'the dark knight trilogy',
  'documentaries',
  'disaster movies',
  'ocean\'s 11',
];

export const historyGeographyCategories = [
  'geography by continent',
  'south and central america',
  'british history',
  'africa',
  'the american civil war',
  'the dawn of civilization',
  'islands',
  'bodies of water',
  'the cold war',
  'eastern europe',
  'explorers',
  'canada',
  'the pacific ocean',
  'india',
  'the roman empire',
  'colonial usa',
  'the civil rights movement',
  'the middle east',
  'germany',
  'antarctica',
  'native american peoples',
  'empires',
  'russia',
  'the atlantic ocean',
  'world war i',
  'australia',
  'the renaissance',
  'dictators',
  'mexico',
  'the louisiana purchase',
  'california',
  'countries that don\'t exist anymore',
  'the netherlands',
  'southeast asia',
  'the fall of the soviet union',
  'japan',
  'the vietnam war',
  'colorado',
  'polynesia',
  'the industrial revolution',
  'the equator',
  'the roaring twenties',
  'deserts',
  'mongolia',
  'feudalism',
  'the space race',
  'mountains',
];

export const foodDrinkCategories = [
  'spices',
  'food and beverage brands',
  'deep fried food',
  'sauce',
  'hors d\'oeuvres',
  'whisk(e)y',
  'chocolate',
  'sausages',
  'tea',
  'wine',
  'sandwiches',
  'breakfast',
  'beans',
  'vodka',
  'east asian food',
  'liqueurs',
  'dessert',
  'steak',
  'coffee',
  'citrus',
  'tequila',
  'candy',
  'mediterranean food',
  'water',
  'pasta',
  'rum',
  'french cuisine',
  'soda',
  'scotch',
  'ice cream',
  'ham',
  'peppers',
  'mexican cuisine',
  'espresso',
  'corn',
  'brandy',
  'burgers',
  'white wine',
  'pancakes',
  'seafood',
  'soup',
  'game',
  'rice',
];

export const randomCategories = [
  'things millennials killed',
  'supercars',
  'orange',
  'orthography',
  'romantic composers',
  'the end of the world',
  'monsters',
  'strongman',
  'ships and boats',
  'age restrictions',
  'medieval weaponry',
  'winter sports',
  'memeology',
  'egyptian mythology',
  'hallucinogens',
  'the olympics',
  'percussion',
  'musical instruments',
  'meme platforms',
  'hell',
  'crazy world records',
  'airplanes',
  'font',
  'the internet',
  'national parks',
  'home appliances',
  'cartography',
  '2009',
  'm i c h a e l',
  'the united nations',
  'fencing',
  'gnomes',
  'things that didn\'t quite work out',
  'public transportation',
  'esports',
  'eating utensils',
  'knights',
  'february 17th',
  'stimulants',
  'fire',
  'sieges',
  'streets',
  'motorcycles',
  'tools',
  'universities',
];
